
@-webkit-keyframes pulse {
  0% {
		-webkit-transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0.7);
  }
  70% {
		-webkit-transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(0,0,0, 0);
  }
  100% {
		-webkit-transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
}
@keyframes pulse {
  0% {
		-moz-transform: scale(0.95);
    -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0.7);
		transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0,0,0, 0.7);
  }
  70% {
		-moz-transform: scale(1);
    -moz-box-shadow: 0 0 0 10px rgba(0,0,0, 0);
		transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0,0,0, 0);
  }
  100% {
		-moz-transform: scale(0.95);
    -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
		transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
}

.admin-home__wrapper {
  max-width: 768px;
  margin: 0 auto;
  background-color: var(--input-color);
  padding-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .admin-home__wrapper {
    max-width: 100%;
  }
}

/* HERO SECTION */

.page-hero__wrapper {
  background: var(--bg-color);
  border-radius: 0px 0px 32px 32px;
}

.hero-cover-image {
  width: 100%;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

.hero-cover-image img {
  height: 100%;
  width: 100%;
}

.hero-image__wrapper {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--border-color);
}

.user-profile__wrapper {
  margin-top: -60px;
  padding:  0 0 36px 0;
}

.profile-avatar {
  cursor: pointer;
  padding-left: 36px;
}

.user-avatar__section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background-color: var(--bg-color);
  border-radius: 50%;
}

.avatar-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

.avatar-image.skeleton {
  border-radius: 50%;
}

.avatar-image__wrapper img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.user-bio__section {
  margin-top: 24px;
  padding: 0 36px 0 36px;
}

.user-bio__section h5,
.newsletter-content h5 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 800;
  background: linear-gradient(44.1deg,#e6145b 8.87%,#2974e2 51.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.newsletter-content h5 {
  text-transform: capitalize;
}

.user-bio__section p,
.newsletter-content p {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

.user-bio__section h5.skeleton {
  height: 14px;
  width: 120px;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #eeeeee, #dadada, #ebebeb);
  background-size: 200%;
}

.user-bio__section p.skeleton {
  height: 12px;
  width: 250px;
}

.user-social__handles {
  padding: 24px 0 0 0;
  display: flex;
  column-gap: 10px;
}

.user-social__handles > div {
  cursor: pointer;
}

.social-icon {
  background: #E6145B;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon.none {
  background: #CCCECF;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.social-icon.none:hover {
  box-shadow: 0 0 0 5px #cccecf3d;
  transform: scale(0.95);
}

.social-icon svg {
  color: #FFFFFF;
  height: 16px;
}

.social-icon img {
  height: 20px;
}

.social__wrapper {
  position: relative;
  width: 48px;
  height: 48px;
  border: 1px solid transparent;
  border-image-slice: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social__wrapper::before {
  content: "";
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  border-radius: 50%;
  background: linear-gradient(54.1deg, #E6145B 16.87%, #2974E2 101.93%) border-box;
  padding: 1px;
  mask: 
  linear-gradient(#fff 0 0) content-box, 
  linear-gradient(#fff 0 0);
  -webkit-mask: 
  linear-gradient(#fff 0 0) content-box, 
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
       mask-composite: exclude; 
}

.user-action__ctn {
  display: flex;
  column-gap: 15px;
  margin-top: 24px;
}

.user-action__ctn button {
  border-radius: 20px;
  border: none;
  padding: 10px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.user-action__ctn button:first-of-type {
  background: var(--border-color);
  color: var(--primary-color);
}

.user-action__ctn button:last-of-type {
  background: transparent;
  border: 1px solid #AAADAF;
  color: #AAADAF;
  transition: all 0.2s ease-in-out;
}

.user-action__ctn button:last-of-type:hover {
  background: #E6145B;
  color: #fff;
  border: 1px solid #E6145B;
}

.modal-title.share {
  padding: 24px 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title.share button {
  background-color: transparent;
  border: none;
}

.modal-title.share button svg {
  height: 16px;
}

.modal-body__section .modal-item {
  padding: 0 1rem;
}

.modal-item button {
  padding: 1rem 0;
  width: 100%;
  border: none;
  background-color: transparent;
  border-bottom: 0.8px solid #EAEAEB;
  cursor: pointer;
  font-weight: bold;
}

.modal-body__section .modal-item:first-of-type button {
  color: #2974e2;
}

.modal-body__section .modal-item:last-of-type button {
  color: #E6145B;
}

@media screen and (max-width: 480px) {
  .user-profile__wrapper {
    padding: 0 0 32px 0;
  }

  .profile-avatar {
    padding-left: 32px;
  }

  .user-bio__section {
    padding: 0 32px 0 32px;
  }
}

@media screen and (max-width: 360px) {
  .user-profile__wrapper {
    padding: 0 0 24px 0;
  }

  .profile-avatar {
    padding-left: 24px;
  }

  .user-bio__section {
    padding: 0 24px 0 24px;
  }

  .user-avatar__section {
    width: 100px;
    height: 100px;
  }

  .avatar-image {
    width: 90px;
    height: 90px;
  }
}

/* DETAILS SECTION */

.admin-details__wrapper {
  padding: 48px;
}

.user-details__wrapper {
  padding: 20px 48px 48px 48px;
}

.tab-header__section {
  display: flex;
  background: var(--bg-color);
  border-radius: 20px;
}

.tab-header__section div {
  padding: 14px 0;
  width: 33.4%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--another-color);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.tab-header__section.section-one div {
  width: 50%;
}

.tab-header__section div:not(:last-of-type) {
  border-right: 0.8px solid var(--border-color);
}

.tab-header__section .header-one.active,
.tab-header__section .header-one:hover,
.tab-header__section .header-two.active,
.tab-header__section .header-two:hover,
.tab-header__section .header-three.active,
.tab-header__section .header-three:hover {
  background: var(--border-color);
  color: var(--primary-color);
}

.tab-header__section .header-one.active,
.tab-header__section .header-one:hover {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.tab-header__section .header-three.active,
.tab-header__section .header-three:hover {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.empty-details__wrapper {
  margin-top: 20px;
  border: 2px dashed var(--border-color);
  border-radius: 20px;
  height: 400px;
}

.empty-details__content {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-item__btn {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: var(--heading-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0,0,0, 0.4);
	transform: scale(1);
  animation: pulse 2s infinite;
}

.add-item__btn:hover {
  animation: none;
}

.add-item__btn svg {
  color: var(--bg-color);
}

.details-description {
  margin-top: 20px;
}

.details-description p {
  font-weight: 300;
  color: #808487;
  line-height: 24px;
}

.details-description p:not(:last-of-type) {
  margin-bottom: 10px;
}

.details-description p span {
  font-weight: 700;
}

@media screen and (max-width: 360px) {
  .admin-details__wrapper {
    padding: 24px;
  }

  .user-details__wrapper {
    padding: 15px 24px 24px 24px;
  }
}

/* LINK DISPLAY SECTION */

.link-display__wrapper {
  margin-top: 20px;
}

.display-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.delete-icon {
  display: flex;
  column-gap: 10px;
  align-items: center;
  background-color: var(--bg-color);
  border-radius: 20px;
  padding: 12px 18px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #E61715;
  margin-bottom: 40px;
  cursor: pointer;
}

.newsletter__wrapper {
  background-color: var(--bg-color);
  border-radius: 20px;
  padding: 24px;
  margin-bottom: 20px;
}

.newsletter-content form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.newsletter-content form input {
  margin-bottom: 10px;
  background: var(--input-color);
  color: var(--font-color);
  border-radius: 12px;
  padding: 13px 24px;
  border: none;
  height: 56px;
}

.newsletter-content form input:focus-visible {
  outline: 1px solid #E6145B;
}

.newsletter-content form button {
  color: #FFFFFF;
  background: #E6145B;
  border-radius: 12px;
  padding: 12px 0px;
  height: 56px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

@media screen and (max-width: 480px) {
  .newsletter__wrapper {
    padding: 24px 18px;
  }
}

/* ANALYTICS SECTION */

.analytics__wrapper {
  margin-top: 20px;
}

.analytics__wrapper .analytics-content__wrapper:first-of-type {
  width: 100%;
}

.analytics__wrapper .analytics-content__wrapper:not(:last-of-type) {
  margin-bottom: 20px;
}

.analytics-content__wrapper {
  background-color: var(--bg-color);
  padding: 24px;
  border-radius: 20px;
}

.analytics-content__wrapper.daily {
  padding: 24px 0;
}

.analytics-content__wrapper > h4 {
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  color: #000A0F;
  display: flex;
  align-items: center;
  column-gap: 0.55rem;
  margin-bottom: 20px;
}

.analytics-content {
  /* display: grid;
  column-gap: 20px;
  row-gap: 20px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 25px;
  column-gap: 5%;
}

.analytics-content.location {
  flex-direction: column;
}

.analytics-content.location h4 {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 0.8px solid #EAEAEB;
}

.analytics-content.location .loader-container {
  display: flex;
  justify-content: center;
}

.analytics-content.daily {
  padding: 24px;
}

.icon-wrapper {
  background-color: #EEEFEF;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.analysis-card {
  padding: 1rem;
  width: 47.5%;
  /* text-align: center; */
  border: 1px solid #F1F1F2;
  border-radius: 12px;
}

.analytics-content:not(.daily) .analysis-card:last-of-type {
  width: 100%;
}

.analysis-card > p {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2A3337;
}

.analysis-card h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.analysis-card div p {
  display: flex;
  column-gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.analysis-card div p:not(:last-of-type) {
  margin-bottom: 5px;
}

.analysis-card > p span:first-of-type {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.analysis-card div p span:first-of-type {
  font-size: 14px;
}

.analysis-card div p span:last-of-type {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.analytics-tab__display {
  width: 100%;
}

/* .location-content__wrapper {
  margin-left: 10px;
} */

.location-content__wrapper .location-item {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  /* border-bottom: 1px solid #cccecf59; */
}

.location-item .line {
  flex-grow: 1;
  border-bottom: 1px dashed #808487;

}

.location-item h5,
.location-item p {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2A3337;
}

.location-item h5 {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.location-item h5 img,
.flag {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.flag {
  background-color: #808487;
}

.tab-display__header {
  display: flex;
  justify-content: space-between;
}

.tabs-wrapper {
  height: 3.5rem;
}

.tabs-wrapper select {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: #F8F8F8;
}

.tabs-wrapper select:focus-visible,
.date-select__wrapper input:focus-visible {
  outline: 1px solid #E6145B;
}

.date-select__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px 24px 24px;
  border-bottom: 0.8px solid #EAEAEB;
}

.date-select__wrapper .date-display {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.date-select__wrapper input {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  background-color: #F8F8F8;
  width: 100%;
}

.date-item {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 40%;
}

.date-select__wrapper .date-item p {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

.date-select__wrapper > button {
  height: 2rem;
  /* width: 5rem; */
  /* border-radius: 6px; */
  /* background-color: #e6145b; */
  background-color: transparent;
  /* color: #fff; */
  border: none;
  cursor: pointer;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.analytics-content .chart-display {
  width: 100%;
  height: 22rem;
}

.analytics-content .chart-display div {
  display: flex;
  justify-content: center;
}

.analytics-content .chart-display p {
  text-align: center;
}

@media screen and (max-width: 560px) {
  .date-select__wrapper {
    row-gap: 10px;
  }

  .date-item {
    width: 100%;
  }

  .analytics__wrapper .analytics-content__wrapper:first-of-type {
    width: 100%;
  }

  .analytics-content {
    column-gap: 2%;
    row-gap: 5px;
  }

  .analysis-card {
    width: 49%;
  }

  .analysis-card > p {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .analysis-card div p {
    flex-wrap: wrap;
    column-gap: 0;
  }

  .analytics-content .chart-display {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 400px) {
  .date-select__wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 360px) {
  .analysis-card {
    padding: 20px;
  }
}

/* SETTINGS TAB */

.settings-card {
  background-color: var(--bg-color);
  border-radius: 20px;
  margin-top: 20px;
}

.section-content {
  padding: 24px;
}

a.section-content {
  text-decoration: none;
  color: #2A3337;
}

.section-content.title {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.section-content.title p span {
  color: #AAADAF;
  font-style: italic;
}

.section-content label {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.section-content label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 5px;
  border: 1px solid #AAADAF;
  background-color: transparent;
}

.section-content input:checked ~ .checkmark {
  background: #2A3337;
  border: none;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.section-content label input:checked ~ .checkmark:after {
  display: block;
}

.section-content label .checkmark:after {
  left: 5.5px;
  top: 3px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.settings-card .section-content:not(:last-of-type) {
  border-bottom: 0.8px solid #EAEAEB;
}

.action-section .section-content:not(a.section-content) {
  color: #E61715;
  cursor: pointer;
}

.action-section .section-content.title {
  border-bottom: 0.8px solid #EAEAEB;
}

label.switch {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 17px;
}

label.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.section-content.title input:checked + .slider {
  background-color: #2A3337;
}

.section-content.title input:focus + .slider {
  box-shadow: 0 0 1px #2A3337;
}

.section-content.title input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* BACK SECTION */

.back-header__wrapper {
  background-color: var(--bg-color);
  padding: 25px 20px;
  border-radius: 0px 0px 24px 24px;
}

.back-header__container {
  display: flex;
  align-items: center;
}

.back-btn {
  width: 5%;
  cursor: pointer;
}

.page-title {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-title.flex {
  display: flex;
}

.page-title.flex h5 {
  width: 90%;
  text-align: center;
}

.page-title.flex button.title-action {
  border: none;
  background: none;
  cursor: pointer;
}

button.title-action svg {
  height: 16px;
  color: #808487;
}

/* MODAL SECTION */
.modal-backdrop,
.content-modal__backdrop {
  position: fixed;
  overflow: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9;
  padding: 6.25rem 0;
}

.modal-display {
  width: 26vw;
  margin: auto;
  background: #fff;
  border-radius: 20px;
}

.modal-display.preview {
  width: 48vw;
}

.success-image {
  margin-top: 30px;
  height: 80px;
  background-image: url(../images/verify.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.verify-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  padding: 0 20px;
}

.verify-content p {
  margin: 20px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.verify-content button {
  color: #FFFFFF;
  background: #E6145B;
  border-radius: 12px;
  padding: 12px 24px;
  height: 50px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.ReactCrop__child-wrapper {
  padding: 10px;
}

.ReactCrop__child-wrapper img {
  width: 100%;
}

.preview-image {
  padding: 20px;
}

.preview-title {
  padding: 20px;
}

.preview-image p {
  margin-bottom: 20px;
}

.select-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.select-btn button {
  color: #FFFFFF;
  background: #E6145B;
  border-radius: 12px;
  padding: 12px 30px;
  height: 56px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.close-modal {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-bottom: 0.8px solid #EAEAEB;

}

.modal-title {
  padding: 24px 54px;
  font-weight: 500;
  border-bottom: 0.8px solid #EAEAEB;
}

.confirm {
  padding: 24px 0;
  text-align: center;
  color: #E61715;
  cursor: pointer;
  border-bottom: 0.8px solid #EAEAEB;
}

.image-select {
  padding: 24px 0;
  text-align: center;
  cursor: pointer;
  border-bottom: 0.8px solid #EAEAEB;
}

.image-select label {
  cursor: pointer;
}

.cover-photos__container {
  padding: 10px;
}

.cover-photos__container .title {
  margin-bottom: 10px;
  text-align: center;
}

.cover-photo__item {
  width: 100%;
  height: 250px;
  position: relative;
}

.cover-photo__item img {
  width: 100%;
  height: 100%;
}

.cover-photo__item .image-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  height: 100%;
  width: 100%;
  background-color: #aaadaf4d;
}

.cover-photo__item .image-overlay.hover {
  display: none;
}

.cover-photo__item:hover .image-overlay.hover {
  display: flex;
}

.cover-photo__item.selected:hover .image-overlay.hover {
  display: none;
}

.close {
  padding: 24px 0;
  text-align: center;
  cursor: pointer;
}

.select {
  padding: 24px 0;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 1350px) {
  .modal-display {
    width: 30vw;
  }
}

@media screen and (max-width: 1200px) {
  .modal-display {
    width: 33vw;
  }
}

@media screen and (max-width: 960px) {
  .modal-display {
    width: 40vw;
  }
}

@media screen and (max-width: 850px) {
  .modal-display {
    width: 46vw;
  }

  .modal-title,
  .confirm,
  .close {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .modal-display.preview {
    width: 56vw;
  }

  .cover-photo__item {
    height: 200px;
  }
}

@media screen and (max-width: 720px) {
  .modal-title {
    padding: 20px 32px;
  }

}

@media screen and (max-width: 576px) {
  .modal-backdrop {
    padding: 4.25rem 0;
  }

  .modal-display {
    width: 58vw;
  }
}

@media screen and (max-width: 480px) {
  .modal-display {
    width: 64vw;
  }
  .modal-title {
    padding: 18px;
  }

  .confirm,
  .text {
    padding: 18px 0;
  }

  .cover-photo__item {
    height: 150px;
  }

  .modal-display.preview {
    width: 85vw;
  }

  .preview-image canvas {
    width: 100% !important;
  }
}

@media screen and (max-width: 360px) {
  .modal-display {
    width: 80vw;
  }
  .modal-title {
    padding: 16px 12px;
  }
  .confirm,
  .text {
    padding: 14px 0;
  }

  .cover-photo__item {
    height: 130px;
  }
}

/* CONTENT DISPLAY MODAL */
.content-modal__display {
  width: 65vw;
  padding: 20px;
  margin: auto;
  background: #fff;
  border-radius: 20px;
}

.content-modal__dialog iframe {
  height: 45rem;
  width: 100%;
}

/* COMPOSE NEWSLETTER SECTION */
/* .compose-newsletter__wrapper {
  margin: 20px 0;
} */

.compose-newsletter__wrapper .input-group {
  margin-top: 20px;
}

.compose-info__wrapper {
  width: 100%;
  border: 1px solid #E6145B;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  column-gap: 10px;
}

.compose-info__wrapper svg {
  color: #E6145B;
}

.compose-info__wrapper .info-content span {
  color: #E6145B;
}

.compose-newsletter__wrapper textarea {
  border-radius: 12px;
  padding: 13px 24px;
  border: none;
  width: 100%;
  resize: none;
}

.compose-newsletter__wrapper textarea:focus-visible {
  outline: 1px solid #E6145B;
}

.compose-newsletter__wrapper button {
  color: #FFFFFF;
  background: #E6145B;
  border-radius: 12px;
  padding: 12px 0px;
  height: 56px;
  width: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  margin-top: 32px;
}

@media screen and (max-width: 576px) {
  .compose-info__wrapper svg {
    width: 20px;
  }
}

@media screen and (max-width: 480px) {
  .compose-info__wrapper svg {
    width: 27px;
  }
}

@media screen and (max-width: 360px) {
  .compose-info__wrapper svg {
    width: 34px;
  }
}

/* SUBSCRIBERS LIST */
.subscribers-list__wrapper {
  margin: 20px 0;
}

.subscriber {
  background-color: var(--bg-color);
  padding: 15px 20px;
  border-radius: 20px;
}

.subscriber.click {
  cursor: pointer;
}

.subscriber.click:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.subscriber:not(:last-of-type) {
  margin-bottom: 20px;
}

.subscriber .user {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.subscriber .user.messages {
  justify-content: space-between;
}

.subscriber .user .user-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(54.1deg, #E6145B 16.87%, #2974E2 101.93%);
}

.subscriber .user .user-image svg {
  height: 20px;
  color: var(--bg-color);
}

.user-details .details-name {
  font-weight: 500;
}

.user p:not(.details-name) {
  color: #AAADAF;
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
}

.user p.details-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2A3337;
}

.user p.details-body,
.user p.details-body h1 {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #AAADAF;
}

.user p.details-info span {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #E6145B;
}

.user p.details-info {
  color: #808487;
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
}

.select-container {
  display: flex;
  column-gap: 8px;
  align-items: center;
  margin-bottom: 20px;
  background-color: #FFFFFF;
  border-radius: 16px;
  padding: 22px 24px;
}

.select-container span {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #E6145B;
}

.select-container select {
  width: 100%;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2A3337;
}

.select-container select:focus-visible {
  outline: none;
}

.message-details__container {
  background: #FFFFFF;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 32px;
}

.subject-section {
  padding-bottom: 16px;
  border-bottom: 0.8px solid #EAEAEB;
}

.subject-section .message-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #2A3337;
}

.body-section {
  padding-top: 16px;
}

.body-section p,
.body-section h1 {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #2A3337;
}

.admin-details__wrapper .edit-btn {
  width: 100%;
  border: none;
  background: #E6145B;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  padding: 18px 0;
  margin-bottom: 16px;
  cursor: pointer;
}

.admin-details__wrapper .delete-btn {
  border: 1px solid #E6145B;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #E6145B;
  padding: 16px 0;
  width: 100%;
  cursor: pointer;
}

.no-subscriber-section {
  width: 60%;
  margin: 45px auto 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.no-subscriber-section p {
  color: #808487;
  margin-top: 15px;
}

@media screen and (max-width: 480px) {
  .no-subscriber-section {
    width: 80%;
    align-items: center;
  }

  .no-subscriber-section img {
    height: 240px;
  }
}

@media screen and (max-width: 360px) {
  .no-subscriber-section img {
    height: 180px;
  }
}