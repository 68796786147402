.accordion-title,
.link__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  background-color: var(--bg-color);
  border-radius: 16px;
}

.accordion-title.skeleton {
  padding: 0;
  height: 60px;
}

.accordion-item {
  cursor: pointer;
}

.accordion-item:not(:last-of-type),
.item-container {
  margin-bottom: 20px;
}

.accordion-title.active {
  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid var(--input-color);
}

.link__title {
  display: flex;
  align-items: center;
}

.link__title .icons {
  display: flex;
  flex-direction: column;
}

.link__title img {
  margin-right: 10px;
  height: 6px;
}

.link__title svg {
  height: 18px;
  margin-right: 10px;
}

.link__title span {
  margin-right: 10px;
}

.icons svg {
  height: 10px;
}

.accordion-content {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color);
  width: 100%;
  padding: 10px;
  padding: 15px 24px;
  border-radius: 0 0 16px 16px;
}

.accordion-content .heading-section {
  position: relative;
  display: flex;
  align-items: center;
}

.accordion-content label {
  cursor: pointer;
}

.accordion-content .input-container {
  background: #CCCECF;
  border-radius: 8px;
  width: 318px;
  height: 228px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 24px;
  margin: 0 auto;
}

label .input-container img {
  margin-bottom: 10px;
}

.input-container p {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000A0F;
  text-align: center;
}

label .input-container p:last-of-type,
.input-container p span {
  color: #838689;
  font-style: italic;
}

#file-upload {
  display: none;
}

.accordion-content > .input-container {
  background: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 400px;
}

.input-container.video {
  background: none;
  padding: 0;
}

.input-container iframe {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.emoji-section {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 2;
}

.heading-section input {
  width: 100%;
}

.heading-section .button {
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.accordion-content input,
.accordion-content textarea {
  border: none;
  padding: 15px 0;
  border-bottom: 1px solid var(--input-color);
}

.accordion-content textarea {
  resize: none;
}

.accordion-content input:focus-visible,
.accordion-content textarea:focus-visible {
  outline: none;
  border-bottom: 1px solid #E6145B;
}

.accordion-content.newsletter .open-btn {
  margin: 20px auto 0 auto;
  width: 50%;
  border: 1px solid #E6145B;
  border-radius: 20px;
  color: #E6145B;
  background-color: transparent;
  height: 50px;
  cursor: pointer;
}

.accordion-content.newsletter input:first-of-type {
  margin-bottom: 10px;
}

.newsletter-options {
  display: flex;
  column-gap: 40px;
  padding: 20px 0;
}

.newsletter-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.newsletter-item input[type='radio'] {
  display: none;
}

.newsletter-item.customise input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.newsletter-item label {
  color: #666;
  font-weight: normal;
}

.newsletter-item.customise label {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.newsletter-item.customise .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
}

.newsletter-item.customise label:hover input ~ .checkmark {
  background-color: #ccc;
}

.newsletter-item.customise input:checked ~ .checkmark {
  background-color: #E6145B;
  border: none;
}

.newsletter-item.customise .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.newsletter-item.customise input:checked ~ .checkmark:after {
  display: block;
}

.newsletter-item.customise .checkmark:after {
  left: 6px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.newsletter-item:not(.customise) label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 2px;
  margin: 0 5px 0 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #666666;
  background-color: transparent;
}

.newsletter-item input[type=radio]:checked + label:after {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 8px;
  content: " ";
  display: block;
  background: #E6145B;
}

.accordion-actions {
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: center;
}

.accordion-actions svg {
  height: 18px;
  cursor: pointer;
}

.accordion-actions .delete {
  color: #AAADAF
}

.accordion-actions .check {
  /* background: #14E6A0; */
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.accordion-actions .check svg {
  color: #fff;
}

@keyframes save-pulse {
  0% {
		-moz-transform: scale(0.95);
    -moz-box-shadow: 0 0 0 0 rgba(20, 230, 160, 0.7);
		transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(20, 230, 160, 0.7);
  }
  70% {
		-moz-transform: scale(1);
    -moz-box-shadow: 0 0 0 10px rgba(20, 230, 160, 0);
		transform: scale(1);
    box-shadow: 0 0 0 10px rgba(20, 230, 160, 0);
  }
  100% {
		-moz-transform: scale(0.95);
    -moz-box-shadow: 0 0 0 0 rgba(20, 230, 160, 0);
		transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(20, 230, 160, 0);
  }
}

@-webkit-keyframes save-pulse {
  0% {
		-webkit-transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(20, 230, 160, 0.7);
  }
  70% {
		-webkit-transform: scale(1);
    box-shadow: 0 0 0 0 rgba(20, 230, 160, 0);
  }
  100% {
		-webkit-transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(20, 230, 160, 0);
  }
}

.accordion-actions .check.pulse {
	transform: scale(1);
  /* border-radius: 4rem; */
  animation: save-pulse 2s infinite;
}

.add-icon {
  margin-top: 10px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  background-color: #E6145B;
  color: #ffff;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  width: 60px;
  font-size: 12px;
}

.add-icon:hover {
  background-color: transparent;
  border: 1px solid #E6145B;
  color: #E6145B;
}

.link__item {
  margin-bottom: 20px;
  text-decoration: none;
  color: var(--primary-color);
}

.link__item:not(.analytics__wrapper .link__item) {
  cursor: pointer;
}

.image-block,
.video-block {
  width: 100%;
}

.image-wrapper,
.video-wrapper {
  width: 318px;
  margin: 0 auto;
}

.video-block iframe {
  width: 100%;
  height: 308px;
  border-radius: 8px;
}

.image-block img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  cursor: pointer;
}

.link__item h5 {
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 10px;
}

.link__item .link-btn {
  width: 24px;
}

.footer-section {
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
}

.footer-section .brand-logo {
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}

.footer-section .brand-logo.login {
  cursor: pointer;
}

.footer-section .brand-logo img {
  width: 24px;
  margin-right: 5px;
}

.footer-section .brand-logo {
  font-weight: 400;
  color: var(--heading-color);
}

.brand-logo span {
  font-weight: 600;
}

.rdw-editor-wrapper {
  padding: 15px 0;
}

.wrapper-class {
  width: 100%;
  background: #FFFFFF;
  border-radius: 12px;
  height: 30rem;
}

.rdw-editor-toolbar.toolbar-class {
  background: #F3F3F3;
  border-radius: 12px;
}

.rdw-editor-main {
  min-height: 100px !important;
  border-bottom: 1px solid var(--input-color);
}

.rdw-editor-main.editor-class {
  height: 80%;
  overflow: auto;
}

@media screen and (max-width: 920px) {
  .accordion-content.newsletter .open-btn {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .accordion-content .input-container,
  .video-block iframe {
    width: 100%;
    height: auto;
  }

  .image-wrapper, .video-wrapper {
    width: 100%;
  }

  .accordion-content > .input-container {
    padding: 0;
    height: 218px;
  }

  .accordion-title,
  .link__item {
    padding: 20px 18px;
  }
}

@media screen and (max-width: 380px) {
  .emoji-section {
    right: -15px;
  }

  .accordion-content .input-container {
    padding: 40px 10px;
  }

  .input-container.video {
    padding: 0;
  }
}
