.edit-profile__wrapper {
  margin-top: 40px;
  max-width: 768px;
  margin: 0 auto;
  min-height: 100vh;
  background-color: var(--input-color);
}

.edit-profile__wrapper.main-wrapper {
    background-color: var(--bg-color);
}

.select-container {
    display: flex;
    column-gap: 8px;
    align-items: center;
    margin-bottom: 20px;
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 22px 24px;
}

.select-container span {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #E6145B;
}

.select-container select {
    width: 100%;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #2A3337;
}

.select-container select:focus-visible {
    outline: none;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  
  .input-group:last-of-type {
    margin-bottom: 10px;
  }
  
  .input-group label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    color: var(--secondary-color);
  }
  
  .input-group input {
    border-radius: 12px;
    padding: 13px 24px;
    border: none;
    width: 100%;
    height: 56px;
  }
  
  .input-group input:focus-visible {
    outline: 1px solid #E6145B;
  }


.password-container {
    display: flex;
    align-items: center;
  }
  
  .password-container div {
    position: absolute;
    left: 27%;
  }
  
  .password-container div svg {
    height: 14px;
    color: #808487;
    cursor: pointer;
  }

small {
    color: #E6145B;
    font-size: 12px;
    margin-top: 5px;
  }
  
  @media screen and (max-width: 768px) {
    .edit-profile__wrapper {
      max-width: 100%;
    }
  }


.cover-image__section {
    width: 100%;
    height: 150px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.cover-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--bg-color);
    cursor: pointer;
}

.user-profile__wrapper {
    position: relative;
}

.avatar__section {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.avatar-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--bg-color);
    border-radius: 50%;
    cursor: pointer;
}

.profile-form__section {
    padding: 0 36px 0 36px;
}

.profile-form__section .input-group {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  

.profile-form__section .input-group label {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    color: var(--secondary-color);
}

.profile-form__section .input-group input,
.profile-form__section .input-group textarea {
    border-radius: 0;
    border: none;
    background-color: transparent;
    border-bottom: 0.8px solid #EAEAEB;
    padding: 5px 0;
}

.profile-form__section .input-group textarea {
    resize: none;
}

.userlink-container {
    display: flex;
    column-gap: 5px;
    align-items: center;
    border-bottom: 0.8px solid #EAEAEB;
}

.userlink-container > input {
    border-bottom: none;
}

.userlink-container p {
    color: var(--secondary-color);
    font-weight: 600;
    height: 100%;
    font-size: 14px;
    line-height: 20px;
}

.profile-form__section .section {
    margin-top: 40px;
}

.socials-section {
    padding: 25px 0;
}

.social-item {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.social-item:not(:last-of-type) {
    margin-bottom: 25px;
}

.social-item input {
    width: 100%;
    height: 40px;
    border: none;
    border-bottom: 0.8px solid #EAEAEB;
}

.social-item > svg {
  cursor: pointer;
}

.social-icon {
  background: #E6145B;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon svg {
  color: #FFFFFF;
  height: 16px;
}

.profile-form__section button,
.support-section button {
    color: #FFFFFF;
    background: #E6145B;
    border-radius: 12px;
    padding: 12px 0px;
    height: 56px;
    width: 100%;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    margin-top: 32px;
}


.profile-form__section .input-group input:focus-visible,
.profile-form__section .input-group textarea:focus-visible,
.social-item input:focus-visible {
    outline: none;
    border-bottom: 1px solid #E6145B;
}

#image-upload,
#avatar-upload {
    display: none;
}

.add-social__btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-social__btn button {
    width: 30%;
    border: 1px solid #E6145B;
    background-color: transparent;
    color: #E6145B;
    border-radius: 40px;
}

.add-social__btn button svg {
    margin-right: 10px;
}

.rdw-editor-wrapper {
    padding: 15px 0;
  }
  
  .wrapper-class {
    width: 100%;
    background: #FFFFFF;
    border-radius: 12px;
    height: 30rem;
  }

  .rdw-editor-toolbar.toolbar {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  .rdw-editor-toolbar.toolbar-class {
    background: #F3F3F3;
    border-radius: 12px;
  }
  
  .rdw-editor-main {
    min-height: 100px !important;
    border-bottom: 1px solid var(--input-color);
  }

  .rdw-editor-main.editor {
    background-color: #FFFFFF;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  
  .rdw-editor-main.editor-class {
    height: 80%;
    overflow: auto;
  }

@media screen and (max-width: 480px) {
    .admin-details__wrapper {
        padding: 42px 36px;
    }

    .add-social__btn button {
        width: 60%;
    }

    .profile-form__section {
        padding: 0 32px 0 32px;
    }
}

@media screen and (max-width: 360px) {
    .admin-details__wrapper {
        padding: 36px;
    }
    .add-social__btn button {
        width: 70%;
    }

    .profile-form__section {
        padding: 0 24px 0 24px;
    }
}

/* SUPPORT */
.support-section {
    padding: 10px 40px;
}

.support-section textarea {
    border-radius: 12px;
    padding: 13px 24px;
    border: none;
    width: 100%;
    resize: none;
}

.support-section textarea:focus-visible {
    outline: 1px solid #E6145B;
}

.social-contact__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 0 100px 0;
}

.social-contact__section h5 {
    margin-bottom: 20px;
}

.contact__handles {
    display: flex;
    column-gap: 40px;
}

.contact__handles svg {
    color: #AAADAF;
}

/* CHANGE PASSWORD */
.support-section .password-container div {
    left: auto;
    right: 32%;
}

.support-section.change-password {
    margin-bottom: 150px;
}

@media screen and (max-width: 1600px) {
    .support-section .password-container div {
        right: 30%;
    }
}

@media screen and (max-width: 1440px) {
    .support-section .password-container div {
        right: 27%;
    }
}

@media screen and (max-width: 1350px) {
    .support-section .password-container div {
        right: 25%;
    }
}

@media screen and (max-width: 1200px) {
    .support-section .password-container div {
        right: 21%;
    }
}

@media screen and (max-width: 960px) {
    .support-section .password-container div {
        right: 16%;
    }
}

@media screen and (max-width: 850px) {
    .support-section .password-container div {
        right: 11%;
    }
}

@media screen and (max-width: 720px) {
    .support-section .password-container div {
        right: 8%;
    }
}

@media screen and (max-width: 576px) {
    .support-section .password-container div {
        right: 10%;
    }
}

@media screen and (max-width: 850px) {
    .support-section .input-group input {
        padding: 13px 12px;
    }
}

@media screen and (max-width: 375px) {
    .support-section .password-container div {
        right: 12%;
    }
}

/* NEWSLETTER SCREENS */
.newsletter-card {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-radius: 20px;
  border: 1px solid #CCCECF;
  background-color: #FFFFFF;
  align-items: center;
  cursor: pointer;
}

.newsletter-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.admin-details__content .newsletter-card:not(:last-of-type) {
  margin-bottom: 8px;
}

.newsletter-card .card-content {
  display: flex;
  column-gap: 16px;
}

.newsletter-card .card-content.one-text {
  align-items: center;
}

.newsletter-card .card-title h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2A3337;
}

.newsletter-card .card-title h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2A3337;
}

.newsletter-card .card-title h4 span {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  background: linear-gradient(54.1deg, #E6145B 16.87%, #2974E2 101.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.newsletter-card .card-title p {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #808487;
}

.search-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px;
    background: #F3F3F4;
    border: 1px solid #AAADAF;
    border-radius: 12px;
    align-items: center;
    cursor: pointer;
    margin: 24px 0;
}

.search-icon {
  display: flex;
  align-items: center;
  padding-right: 14px;
  border-right: 0.8px solid #808487;
}

.search-icon svg {
    color: #808487;
    height: 16px;
}

.search-section__wrapper {
  width: 100%;
  padding-left: 16px;
}

.search-section__wrapper input {
    border: none;
    width: 100%;
    background-color: transparent;
}