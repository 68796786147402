.item-modal__wrapper {
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: rgba(255, 255, 255, 0.96);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-modal__wrapper {
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.item-modal__content,
.image-modal__content {
  height: 100%;
}

.modal-body {
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-modal__content {
  width: 50%;
}
.modal-body h5 {
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  font-weight: 800;
  background: linear-gradient(54.1deg, #E6145B 16.87%, #2974E2 101.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 30px;
}

.modal-body p {
  color: #2A3337;
  text-align: center;
}

.items__wrapper {
  padding: 50px 0;
  width: 100%;
}

.image-container {
  height: 80%;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 8px;
}

.add-item {
  display: flex;
  column-gap: 10px;
  align-items: center;
  color: #2A3337;
  padding: 30px 20px;
  cursor: pointer;
  border-top: 0.8px solid #EAEAEB;
  border-bottom: 0.8px solid #EAEAEB;
}

.add-item:last-of-type {
  border-top: none;
}

.add-item span {
  font-weight: 500;
  background: linear-gradient(54.1deg, #E6145B 16.87%, #2974E2 101.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.add-item p {
  font-weight: 300;
  font-style: italic;
  color: #838689;
}

.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  background-color: var(--heading-color);
  color: var(--bg-color);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* SOCIAL MODAL */

.socials__wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 40px 0;
}

.socials-item__wrapper {
  width: 33%;
  border-top: 0.8px solid #EAEAEB;
  border-bottom: 0.8px solid #EAEAEB;
  padding: 30px 0;
}

.socials-item {
  padding: 0 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inactive-container,
.active-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active-container {
  cursor: pointer;
}

.active-container p {
  margin-top: 10px;
}

.social-icon {
  background: #E6145B;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon svg,
.inactive-icon svg {
  color: #FFFFFF;
  height: 16px;
}

.inactive-container p {
  color: #AAADAF;
  margin-top: 10px;
}

.inactive-icon {
  background-color: #CCCECF;
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}

.inactive-icon img,
.social-icon img {
  height: 20px;
}

.socials-item__wrapper:not(:nth-child(3n)) .socials-item {
  border-right: 1px solid #AAADAF;
}

@media screen and (max-width: 480px) {
  .image-modal__content {
    width: 100%;
  }
}