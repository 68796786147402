/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #2A3337;
  --secondary-color: #2A3337;
  --font-color: #000a0f;
  --bg-color: #FFFFFF;
  --heading-color: #000000;
  --border-color: #EAEAEB;
  --input-color: #F1F1F2;
  --another-color: #CCCECF;
  --another-bg-color: #E5E5E5;
}

[data-theme="dark"] {
  --primary-color: #AAADAF;
  --secondary-color: #CCCECF;
  --font-color: #FFFFFF;
  --bg-color: #000a0f;
  --heading-color: #FFFFFF;
  --border-color: #2A3337;
  --input-color: #1D2225;
  --another-color: #2A3337;
  --another-bg-color: #242A2E;
}

body {
  font-family: 'Inter', sans-serif;
  background: var(--another-bg-color);
  color: var(--primary-color);
  transition: all 0.3s ease-in-out;
}

textarea,
select {
  font-family: 'Inter', sans-serif;
  color: var(--primary-color);
}

button,
::placeholder,
input {
  font-family: 'Inter', sans-serif;
}

input:disabled {
  background: transparent;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #4a4848 !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #4a4848 !important;
}

.app-loader__wrapper {
  height: 100vh;
  width: 100%;
  background-color: #FFFFFF;
}

.app-loader__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader-image {
  height: 120px;
  width: 120px;
  margin-bottom: 20px;
}

.loader-image img {
  width: 100%;
}

.loader-progress {
  background: #EAEAEB;
  border-radius: 20px;
  height: 4px;
  width: 200px;
}

.child-element {
  background: linear-gradient(54.1deg, #E6145B 16.87%, #2974E2 101.93%);
  height: 4px;
  border-radius: 20px;
}

@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
    -webkit-transform: rotate(360deg); 
    transform:rotate(360deg); 
  } 
}

@-moz-keyframes skeleton {
  0% {
    background-position: 0% 10%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@-webkit-keyframes skeleton {
  0% {
    background-position: 0% 10%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@keyframes skeleton {
  0% {
    background-position: 0% 10%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@keyframes grow {
  from {transform: scale(0,0); opacity: 0;}
  to {transform: scale(1,1); opacity: 1;}
}

@keyframes move {
  from {transform: translateX(0px)}
  to {transform: translateX(45px)}
}

.rdw-link-modal,
.rdw-embedded-modal {
  height: auto !important;
}

.rdw-editor-toolbar {
  border-radius: 16px !important;
}

.rdw-link-modal-buttonsection,
.rdw-image-modal-btn-section {
  width: 100%;
}

button.rdw-link-modal-btn {
  margin: 0;
}

.rdw-link-modal-target-option {
  display: flex;
  align-items: center;
}

#openLinkInNewWindow {
  width: 15px;
  height: 15px;
}

.loader {
  border: 5px solid #c5c1c1;
  border-top: 5px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

.loader.dark {
  width: 80px;
  height: 80px;
  border: 2px solid #c5c1c1;
  border-top: 2px solid #fff;
}

.skeleton {
  background: linear-gradient(90deg, #eeeeee, #dadada, #ebebeb);
  background-size: 200%;
  animation: skeleton 1s ease-in-out infinite;
  -webkit-animation: skeleton 1s ease-in-out infinite;
}

/* TOGGLE BUTTON */
.toggle-mode__wrapper {
  position: fixed; 
  left: 92%;
  bottom: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(128, 132, 135, 0.6);
  border-radius: 800px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  background: var(--heading-color);
  color: var(--bg-color);
}

.toggle-mode__wrapper svg {
  height: 20px;
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 12rem;
}


.loading-wrapper .container{
  height: 15px;
  width: 105px;
  display: flex;
  position: relative;
}

.loading-wrapper .container .dot{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: blue;
  animation: move 700ms linear 0ms infinite;
  margin-right: 30px;
}

.loading-wrapper .container .dot:first-child{
  position: absolute;
  top:0;
  left:0;
  animation: grow 700ms linear 0ms infinite;
}
    
.loading-wrapper .container .dot:last-child{
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0;
  animation: grow 700ms linear 0s infinite reverse;
}

@media screen and (max-width: 1350px) {
  .toggle-mode__wrapper {
    left: 91%;
  }
}

@media screen and (max-width: 1152px) {
  .toggle-mode__wrapper {
    left: 89.5%;
  }
}

@media screen and (max-width: 960px) {
  .toggle-mode__wrapper {
    left: 88%;
  }
}

@media screen and (max-width: 850px) {
  .toggle-mode__wrapper {
    left: 85%;
  }
}

@media screen and (max-width: 720px) {
  .toggle-mode__wrapper {
    left: 84%;
  }
}

@media screen and (max-width: 576px) {
  .toggle-mode__wrapper {
    left: 80%;
  }
}

/* PREVIEW MODE */
.preview-mode__wrapper {
  cursor: pointer;
  z-index: 1;
}

.preview-section {
  position: fixed;
  bottom: 10px;
  left: 46%;
  background-color: var(--heading-color);
  color: var(--bg-color);
  border-radius: 800px;
  padding: 15px 20px;
}

.usermode-section {
  position: fixed;
  bottom: 5px;
  left: 40%;
  text-align: center;
}

.usermode-section p {
  margin-top: 10px;
  font-size: 14px;
}

.usermode-content {
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 15px 20px;
  background-color: var(--heading-color);
  color: var(--bg-color);
  border-radius: 800px;
}

.usermode-content div {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.usermode-content img {
  height: 20px;
}

.usermode-content span {
  font-size: 14px;
}

.usermode-content span.title {
  color: var(--another-color);
}

.usermode-content input {
  background-color: transparent;
  border: none;
  color: var(--bg-color);
}

.usermode-content input:focus-visible {
  outline: none;
}

.usermode-content .cancel-btn {
  width: 16px;
}

.preview-section p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.preview-section p svg {
  margin-right: 5px;
}

.close-section {
  position: fixed;
  bottom: 10px;
  left: 49%;
  background-color: var(--heading-color);
  color: var(--bg-color);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-section svg {
  height: 22px;
}

@media screen and (max-width: 1200px) {
  .preview-section {
    left: 45%;
  }

  .usermode-section {
    left: 35%;
  }
}

@media screen and (max-width: 960px) {
  .preview-section {
    left: 44%;
  }
}

@media screen and (max-width: 850px) {
  .preview-section {
    left: 42.5%;
  }

  .usermode-section {
    left: 30%;
  }
}

@media screen and (max-width: 720px) {
  .preview-section {
    left: 41.5%;
  }
}

@media screen and (max-width: 576px) {
  .preview-section {
    left: 39.5%;
  }
}

@media screen and (max-width: 480px) {
  .preview-section {
    left: 38%;
    padding: 12px 16px;
    font-size: 14px;
  }

  .usermode-section {
    left: 12%;
  }
}

@media screen and (max-width: 400px) {
  .usermode-section {
    left: 8%;
  }

  .preview-section {
    left: 36%;
  }
}

@media screen and (max-width: 375px) {
  .usermode-section {
    left: 6%;
    bottom: 30px;
  }
}

@media screen and (max-width: 360px) {
  .preview-section {
    left: 34%;
  }

  .usermode-section {
    left: 4%;
  }
}

/* HEADER SECTION */
.page-header__content {
  padding: 40px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-logo__wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
}

.page-logo__wrapper img {
  width: 48px;
  height: 48px;
}

.logo-name {
  font-size: 28px;
  color: var(--heading-color);
  font-weight: 300;
}

.logo-name span {
  font-weight: 700;
}

.page-header__social {
  display: block;
}

.page-header__social a svg {
  color: var(--secondary-color);
  width: 20px;
  height: 20px;
}

.page-header__social a {
  padding: 0 20px;
}

.page-header__social a:last-of-type {
  padding-right: 0;
}

.mobile-menu__section,
.menu-dropdown__content {
  display: none;
}

@media screen and (max-width: 960px) {
  .page-header__wrapper {
    position: fixed;
    background: var(--bg-color);
    width: 100%;
  }

  .page-logo__wrapper img {
    width: 40px;
    height: 40px;
  }

  .logo-name {
    font-size: 24px;
  }

  .page-header__content {
    border-bottom: 1px solid var(--border-color);
  }

  .page-header__social {
    display: none;
  }

  .mobile-menu__section,
  .menu-dropdown__content {
    display: block;
  }

  .menu-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }

  .menu-icon {
    width: 18px;
    height: 2px;
    border-radius: 5px;
    background: var(--heading-color);
    transition: all 0.5s ease-in-out;
  }

  .menu-icon::before,
  .menu-icon::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 2px;
    border-radius: 5px;
    background: var(--heading-color);
    transition: all 0.5s ease-in-out;
  }

  .menu-icon::before {
    transform: translateY(-6px);
  }

  .menu-icon::after {
    transform: translateY(6px);
  }

  .menu-btn.open .menu-icon {
    transform: translateX(-20px);
    background: transparent;
  }

  .menu-btn.open .menu-icon::before {
    transform: rotate(45deg) translate(15px, -15px);
  }
  
  .menu-btn.open .menu-icon::after {
    transform: rotate(-45deg) translate(15px, 15px);
  }

  .menu-dropdown__content {
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    background: var(--bg-color);
    padding: 20px 0;
  }

  .menu-dropdown__content h5 {
    font-size: 32px;
    line-height: 48px;
    font-weight: 900;
    background: linear-gradient(54.1deg, #E6145B 16.87%, #2974E2 101.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    width: 40%;
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: center;
  }

  .page-mobile__social {
    display: flex;
    flex-direction: column;
  }

  .page-mobile__social a {
    text-decoration: none;
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .page-mobile__social a:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 570px) {
  .page-header__content {
    padding: 30px 60px;
  }

  .menu-dropdown__content {
    height: calc(100vh - 96px);
  }

  .menu-dropdown__content h5 {
    font-size: 24px;
    line-height: 32px;
    width: 50%;
  }
}

@media screen and (max-width: 450px) {
  .page-header__content {
    padding: 30px 40px;
  }
}

@media screen and (max-width: 320px) {
  .page-header__content {
    padding: 30px;
  }
}

/* PAGE BODY SECTION */
.page-body__section {
  padding: 20px 80px;
  display: flex;
  align-items: center;
}

.page-content {
  width: 70%;
}

.page-content h1 {
  font-size: 64px;
  line-height: 74px;
  font-weight: 900;
  background: linear-gradient(54.1deg, #E6145B 16.87%, #2974E2 101.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 20px;
}

.page-content p {
  line-height: 32px;
}

.page-content form {
  margin-top: 30px;
  display: flex;
}

.page-content form input {
  background: var(--input-color);
  border-radius: 12px;
  padding: 13px 24px;
  border: none;
  width: 340px;
  margin-right: 10px;
  height: 56px;
}

.page-content form input:focus-visible {
  outline: 1px solid #E6145B;
}

.page-content form button {
  color: #FFFFFF;
  background: #E6145B;
  border-radius: 12px;
  padding: 12px 0px;
  height: 56px;
  width: 140px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.page-content form button:hover {
  color: #E6145B;
  background-color: var(--bg-color);
  border: 0.5px solid #E6145B;
}

@media screen and (max-width: 1350px) {
  .page-content {
    width: 80%;
  }
}

@media screen and (max-width: 1200px) {
  .page-content {
    width: 90%;
  }

  .page-content h1 {
    font-size: 60px;
  }

  .page-content form input {
    width: 300px;
    height: 50px;
  }

  .page-content form button {
    width: 120px;
    height: 50px;
  }
}

@media screen and (max-width: 1096px) {
  .page-body__section {
    padding: 30px 80px;
    row-gap: 20px;
    flex-direction: column-reverse;
  }

  .page-content {
    width: 100%;
  }

  .page-content form {
    display: grid;
  }

  .page-content form input {
    width: 100%;
    margin-bottom: 10px;
  }

  .page-content form button {
    width: 100%;
  }
}

@media screen and (max-width: 960px) {
  main {
    padding-top: 120px;
  }
}

@media screen and (max-width: 576px) {
  .page-content h1 {
    font-size: 48px;
    line-height: 56px;
  }

  .page-image__section {
    width: 100%;
  }

  .page-image__section img {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .page-body__section {
    padding: 30px 60px;
  }
}

@media screen and (max-width: 450px) {
  .page-body__section {
    padding: 30px 40px;
  }
}

@media screen and (max-width: 360px) {
  .page-content h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

@media screen and (max-width: 320px) {
  .page-body__section {
    padding: 30px;
  }
}

/* PAGE FOOTER SECTION */
footer {
  margin-top: auto;
}

.page-footer__content {
  padding: 40px 80px;
  display: flex;
  justify-content: center;
}

.page-footer__content p {
  color: #808487;
  font-size: 14px;
}

@media screen and (max-width: 960px) {
  .page-footer__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .page-footer__content {
    padding: 20px 60px;
  }
}

@media screen and (max-width: 450px) {
  .page-footer__content {
    padding: 20px 40px;
  }
}

@media screen and (max-width: 320px) {
  .page-footer__content {
    padding: 20px 30px;
  }
}

.react-tel-input {
  margin-bottom: 10px !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  background: var(--input-color) !important;
  border-radius: 12px !important;
  padding: 13px 24px;
  border: none !important;
  height: 56px !important;
  padding-left: 58px !important;
}

.react-tel-input .flag-dropdown {
  border-radius: 12px 0 0 12px !important;
}

.react-tel-input .selected-flag {
  width: 48px !important;
  border-radius: 12px 0 0 12px !important;
}

.react-tel-input .selected-flag .arrow {
  left: 25px !important;
}

/* 404 PAGE STYLES */
.error-container {
  background-color: #fff;
}

.error-page__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  margin:  0 auto;
  height: 100vh;
}

.error-image {
  height: 24rem;
  width: auto;
}

.error-image img {
  width: 100%;
  height: 100%;
}

.error-page__cta {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.error-page__cta button {
  color: #fff;
  background-color: #E6145B;
  border-radius: 6px;
  padding: 12px 14px;
  border: 1px solid #E6145B;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.error-page__cta button:hover {
  color: #E6145B;
  background-color: #fff;
}

@media screen and (max-width: 850px) {
  .error-page__wrapper {
    width: 55%;
  }
}

@media screen and (max-width: 770px) {
  .error-page__wrapper {
    width: 60%;
  }
  .error-image {
    height: 20rem;
  }
}

@media screen and (max-width: 576px) {
  .error-page__wrapper {
    width: 80%;
  }

  .error-image {
    height: 20rem;
  }
}

@media screen and (max-width: 450px) {
  .error-page__wrapper {
    width: 90%;
  }
}

@media screen and (max-width: 380px) {
  .error-image {
    height: 16rem;
  }
}

/* DATE RANGE PICKER */
.react-daterange-picker__wrapper {
  border: none !important;
}
